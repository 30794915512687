import React from "react";
import {GetMinerPrice, GetMinerProfit, miners} from "../utils/minerItems";
import {HeaderHome} from "../components/headerHome";
import {FarmList} from "../components/farmList";
import {numberPrettier} from "../utils/numbers";


export const Miner = ({userData, setUserData, setActivePage}) => {

    const [minerPopup, setMinerPopup] = React.useState(false)
    const [minerPopupData, setMinerPopupData] = React.useState(null)

    const buyMiner = (miner) => {

        if(userData.balance < miner.cost) return
        setMinerPopup(false)
        setUserData({
            ...userData,
            balance: Number(userData.balance) - Number(miner.cost),

            game_data:{
                ...userData.game_data,
                miners: {
                    ...userData.game_data.miners,
                    [miner.id]: {

                        ...userData.game_data.miners?.[miner.id]||{},
                        level: userData.game_data?.miners?.[miner.id] ? userData.game_data.miners[miner.id].level + 1 : 1
                    }

                }
            }
        })
    }

    return (
        <div>
            <HeaderHome userData={userData} setActivePage={setActivePage}/>
            <div className={'home-pretty-top miner'}>

                <div className={"wrapper-miner-list"}>
                    {miners.map(miner => {
                        miner.level=1
                        let hasBuyButton=true;
                        if(userData.game_data?.miners?.[String(miner.id)]){
                          miner.level=userData.game_data.miners[String(miner.id)].level +1
                            if(miner.level>=8){
                                miner.level=7
                                hasBuyButton=false
                            }
                        }
                        return (
                            <div key={miner.id} className={'miner-list'} onClick={()=>{
                                if(!hasBuyButton) return
                                setMinerPopupData(miner)
                                setMinerPopup(true)
                            }}>
                                <div className={"miner-list-item-top"}>
                                    <img src={miner.image} alt={miner.name}/>
                                    <div className={"miner-list-item-name"}>
                                        <span style={{fontWeight:'bold'}}>{miner.name}</span>
                                        <span style={{textAlign:'left'}}>
                                            <span style={{fontSize:'10.1px', color:'gray', lineHeight:'5px'}}>Profit per Hour</span>
                                        <br/>
                                        🪙{numberPrettier(GetMinerProfit(miner))}
                                        </span>
                                    </div>
                                </div>

                                <div className={"miner-list-item-acquire"}>
                                    <div className={"miner-list-item-acquire_first-child"}>Lvl {miner.level}</div>

                                    <div className={"miner-list-item-acquire_second-child"}>🪙{numberPrettier(GetMinerPrice(miner))}</div>
                                </div>
                                {/*<button onClick={() => buyMiner(miner)}>Buy</button>*/}
                            </div>
                        )
                    })}

                    <div className={['miner-popup', minerPopup?'visible':''].join(' ')}>
                        {(minerPopup) &&(
                        <div className={'miner-popup-inner'}>
                            <div className={'miner-popup-inner-close'} onClick={() => setMinerPopup(false)}>X</div>
                            <div className={'miner-popup-inner-title'}>{minerPopupData.name}</div>
                            <div className={'miner-popup-inner-image'}>
                                <img src={minerPopupData.image} alt={minerPopupData.name}/>
                            </div>
                            <div className={'miner-popup-inner-description'}>{minerPopupData.description}</div>
                            <div className={"miner-popup-inner-profit-per-hour"}>
                                Profit Per hour:<br/>
                                +{numberPrettier(GetMinerProfit(minerPopupData))}🪙
                            </div>
                            <div className={'miner-popup-inner-buy'}>
                                <button onClick={() => buyMiner(minerPopupData)}>Buy - {numberPrettier(GetMinerPrice(minerPopupData))}🪙</button>
                            </div>
                        </div>
                        )}
                    </div>

                </div>
            </div>

        </div>

    )
}
