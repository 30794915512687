import React from 'react';
import logo from './logo.svg';
import './App.css';
import {GetServerUserData, GetUserData, UpdateServerUserData} from "./utils/userData";
import {SetUpWebRTC} from "./utils/WebRTC";
import {Home} from "./pages/home";
import {Footer} from "./components/footer";
import {AirDrop} from "./pages/AirDrop";
import {Miner} from "./pages/Miner";
import {GetMinerPrice, GetMinerProfit, miners} from "./utils/minerItems";
import Tasks from "./pages/Tasks";
import {numberPrettier} from "./utils/numbers";
import {Leaderboard} from "./pages/Leaderboard"; // (trystero-torrent.min.js)

let startMiner= new Date()
function App() {
  const [userData, setUserData] = React.useState({username: '', userId: '', is_wallet: false, full:false})

    const partial = GetUserData()

    React.useEffect(() => {
        // fetch('https://api.ipify.org?format=json')
        fetch('https://ipapi.co/json/').then(response => response.json()).then(data => {
            console.log('ip data:', data)
            setUserData({
                ...userData,
                ...partial,
                full: false,
                ip: data.ip,
                country_name: data.country_name||"Unknown",
                country: data.country||"XX",
                org: data.org||"Unknown",

            })
        }).catch(e => {
            fetch('https://api.ipify.org?format=json').then(response => response.json()).then(data => {
                console.log('ip data:', data)
                setUserData({
                    ...userData,
                    ...partial,
                    full: false,
                    ip: data.ip
                })
            })
                .catch(e => {
                    console.log('error getting ip', e)
                    setUserData({
                        ...userData,
                        ...partial,
                        full: false
                    })
                })
        })
    },[])

    React.useEffect(() => {
        console.log('userData Has been updated new:', userData)
      if(userData.full) return
        // setUserData(data)
        SetUpWebRTC(userData)
        GetServerUserData(userData).then(data => {
            if(!data || data===null) return console.log('error getting data')
            console.log('data', data)
            setUserData({
                ...userData,
                ...data.userData,
                full: true
            })
        })


    }, [userData])

    // debounce backend update
    React.useEffect(()=>{
        if(!userData.full) return
        const debounced = setTimeout(() => {
            const sendData = {...userData,
                ip: userData.ip,
                country_name: userData?.country_name||'',
                country: userData?.country||'',
                org: userData?.org||'',
                game_data:{
                    ...userData.game_data,
                    lastOnline: new Date()
                }
            }
            UpdateServerUserData(sendData).then(data => {
                console.log(data)
            })
        }, 600)
        return ()=>clearTimeout(debounced)

    }, [userData])


    React.useEffect(()=>{
    const currentBalance= userData.balance
        if(!userData.full) return
        if(!userData.game_data.miners)return

         const debounced= setInterval(() => {

              const profitHour=  Object.keys(userData.game_data.miners).map(minerId => {
                    return GetMinerProfit({
                            ...miners.find(m => m.id === Number(minerId)),
                            level: userData.game_data.miners[minerId].level
                        }
                    )
                }).reduce((a,b)=>a+b,0)
            const profitSinceStart = (new Date() - startMiner) / 1000 / 3600 * profitHour
            startMiner = new Date()
            setUserData({
                ...userData,
                balance: Number(currentBalance) + profitSinceStart
            })

        }, 3000)

        return ()=>clearInterval(debounced)

    },[userData])

    const [activePage, setActivePage] = React.useState('home')


    const [showPopup, setShowPopup] = React.useState(false)
    const [profitSinceLastOnline, setProfitSinceLastOnline] = React.useState(0)
    const [gaveProfit, setGaveProfit] = React.useState(false)
    React.useEffect(()=>{
        if(!userData.full) return
        if(!userData.game_data.miners)return
        if(gaveProfit) return

        if(userData.game_data?.lastOnline){
            // if more than 30 seconds
            if( new Date() - new Date(userData.game_data.lastOnline) > 60000) {
                const lastOnline = new Date(userData.game_data.lastOnline)
                const now = new Date()
                const profitHour = Object.keys(userData.game_data.miners).map(minerId => {
                    return GetMinerProfit({
                            ...miners.find(m => m.id === Number(minerId)),
                            level: userData.game_data.miners[minerId].level
                        }
                    )
                }).reduce((a, b) => a + b, 0)
                // if more than 3 hours offline, only give 3 hours worth of profit
                let profitSinceStart = (now - lastOnline) / 1000 / 3600 * profitHour
                if(now - lastOnline > 1000 * 60 * 60 * 3) profitSinceStart = 3 * profitHour
                setUserData({
                    ...userData,
                    balance: Number(userData.balance) + profitSinceStart
                })
                if(profitSinceStart>0) setShowPopup(true)
                setProfitSinceLastOnline(profitSinceStart)
                setGaveProfit(true)
            }
        }
    },[userData, gaveProfit])


    const [loaded, setLoaded] = React.useState(false)
    React.useEffect(()=>{
        setTimeout(()=>{
            setLoaded(true)

        }, 3000)

    },[])

    if(!userData.full
    || !loaded) return(

        <div className={"loading-screen"}>
<h1>TapFarm</h1>
            <img src={'/tapFarmIntro.jpeg'} alt={'loading'} className={'loading-img'}/>
            <h4 style={{padding:'40px'}}>
                Get ready to grow, earn, and compete for your share of the massive <span style={{fontWeight:'bolder'}}>1,000,000 BNF3 Token airdrop</span>.
                <br/> <br/>
                Your farming adventure starts now—let’s get growing!
            </h4>
        </div>

    )



  return (
      <div className="App">
          <div className={"page-content"}>
              {activePage === 'home' && <Home userData={userData} setUserData={setUserData} setActivePage={setActivePage}/>}
              {activePage === 'Miner' && <Miner userData={userData} setUserData={setUserData}  setActivePage={setActivePage}/>}
              {activePage === 'Tasks' && <Tasks userData={userData} setUserData={setUserData} setActivePage={setActivePage}/>}
              {activePage === 'AirDrop' && <AirDrop userData={userData} setUserData={setUserData}/>}
              {activePage === 'Leaderboard' && <Leaderboard setActivePage={setActivePage} userData={userData}/>}
          </div>
          <Footer setActivePage={setActivePage}/>


          <div className={['miner-popup', showPopup ? 'visible' : ''].join(' ')}>
              {(showPopup) && (
                  <div className={'miner-popup-inner'}>
                      <div className={'miner-popup-inner-close'} onClick={() => setShowPopup(false)}>X</div>
                      <div className={'miner-popup-inner-title'}>Sir Loin the Boss</div>
                      <div className={'miner-popup-inner-image'}>
                          <img src={'/farm_home.png'} alt={'wow wow'}/>
                      </div>
                      <div className={'miner-popup-inner-description'}>Your farm was busy at work while you were gone!</div>
                      <div className={"miner-popup-inner-profit-per-hour"}>
                          Profit:<br/>
                          +{numberPrettier(profitSinceLastOnline)}🪙
                      </div>
                      <div className={'miner-popup-inner-buy'}>
                          <button onClick={() =>{
                              setShowPopup(false)
                          }}
                          >Cool!🪙
                          </button>
                      </div>
                  </div>
              )}
          </div>

      </div>
  );
}

export default App;
