import React from "react";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {Crops} from "./index";


export const TimerMemoizer = React.memo(({userData, setUserData,item, durations, genericItem, icons,category}) => {

    const onComplete = () => {
        setUserData({
            ...userData,
            game_data: {
                ...userData.game_data,
                [category]: {
                    ...userData.game_data[category],
                    [item]: {
                        ...userData.game_data[category][item],
                        activeStageActions: userData.game_data[category][item].activeStageActions + 1,
                        completedStageActions: 0,
                        // yields: userData.game_data.crops[item].yields + Math.floor(Math.random() * (Crops[crop].maxYield - Crops[crop].minYield + 1)) + Crops[crop].minYield,
                    }
                }
            }
        })
    }
    const [data, setData] = React.useState({userData})
    const [itemStage, setItemStage] = React.useState(userData.game_data[category][item].growthStage)
    const [stageStart, setStageStart] = React.useState(userData.game_data[category][item].stageStart)
    React.useEffect(()=>{
        // setData({userData})
        if(userData.game_data[category][item].growthStage !== itemStage){
            setItemStage(userData.game_data[category][item].growthStage)
            setStageStart(userData.game_data[category][item].stageStart)
        }
    },[userData])


    return(
        <TimerMaturity stageStart={stageStart} itemStage={itemStage} onComplete={onComplete} item={item} durations={durations} genericItem={genericItem} icons={icons} category={category} />
    )

})


/// memoized timer
export const TimerMaturity = React.memo(({stageStart,itemStage, onComplete,item, durations, genericItem, icons,category}) => {
    return(
        <CountdownCircleTimer
            isPlaying
            duration={
                Number(((stageStart + durations[genericItem].growthTime *1000  - Date.now())/1000).toFixed(0))
            }
            initialRemainingTime={
                Number(((stageStart + durations[genericItem].growthTime *1000  - Date.now())/1000).toFixed(0))
            }
            key={item}
            colors={['#776b3a', '#d6f701', '#a3cb67', '#68e729']}
            colorsTime={[7, 5, 2, 0]}
            size={40}
            strokeWidth={4}
            trailColor={'rgba(41,231,66,0.19)'}
            onComplete={onComplete}
        >
            {({ remainingTime }) => icons[itemStage]}

            {/*{CropsGrowthStages[userData.game_data.crops[item].growthStage]}*/}
        </CountdownCircleTimer>
    )
})




/// memoized timer
export const TimerMaturityA = React.memo(({userData, setUserData,item, durations, genericItem, icons,category}) => {
    return(
        <CountdownCircleTimer
            isPlaying
            duration={
                Number(((userData.game_data[category][item].stageStart + durations[genericItem].growthTime *1000  - Date.now())/1000).toFixed(0))
            }
            initialRemainingTime={
                Number(((userData.game_data[category][item].stageStart + durations[genericItem].growthTime *1000  - Date.now())/1000).toFixed(0))
            }
            key={item}
            colors={['#776b3a', '#d6f701', '#a3cb67', '#68e729']}
            colorsTime={[7, 5, 2, 0]}
            size={40}
            strokeWidth={4}
            trailColor={'rgba(41,231,66,0.19)'}
            onComplete={() => {
                setUserData({
                    ...userData,
                    game_data: {
                        ...userData.game_data,
                        [category]: {
                            ...userData.game_data[category],
                            [item]: {
                                ...userData.game_data[category][item],
                                activeStageActions: userData.game_data[category][item].activeStageActions + 1,
                                completedStageActions: 0,
                                // yields: userData.game_data.crops[item].yields + Math.floor(Math.random() * (Crops[crop].maxYield - Crops[crop].minYield + 1)) + Crops[crop].minYield,
                            }
                        }
                    }
                })
            }}
        >
            {({ remainingTime }) => icons[userData.game_data[category][item].growthStage]}

            {/*{CropsGrowthStages[userData.game_data.crops[item].growthStage]}*/}
        </CountdownCircleTimer>
    )
})

/// memoized timer
export const TimerMaturityB = ({userData, setUserData,item, durations, genericItem, icons,category}) => {
    const [data, setData] = React.useState({...userData})
    React.useEffect(()=>{
        setData({...userData})
    },[userData])

    return(
        <CircleCountDown

            time={
            // 200
                Number(((data.game_data[category][item].stageStart + durations[genericItem].growthTime *1000  - Date.now())/1000).toFixed(0))
            }
            initialRemainingTime={
                Number(((data.game_data[category][item].stageStart + durations[genericItem].growthTime *1000  - Date.now())/1000).toFixed(0))
            }
            key={item}
            colors={['#776b3a', '#d6f701', '#a3cb67', '#68e729']}
            colorsTime={[7, 5, 2, 0]}
            size={40}
            strokeWidth={4}
            trailColor={'rgba(41,231,66,0.19)'}
            onComplete={() => {
                setUserData({
                    ...userData,
                    game_data: {
                        ...userData.game_data,
                        [category]: {
                            ...userData.game_data[category],
                            [item]: {
                                ...userData.game_data[category][item],
                                activeStageActions: userData.game_data[category][item].activeStageActions + 1,
                                completedStageActions: 0,
                                // yields: userData.game_data.crops[item].yields + Math.floor(Math.random() * (Crops[crop].maxYield - Crops[crop].minYield + 1)) + Crops[crop].minYield,
                            }
                        }
                    }
                })
            }}
        >
            {({ remainingTime }) => icons[userData.game_data[category][item].growthStage]}

            {/*{CropsGrowthStages[userData.game_data.crops[item].growthStage]}*/}
        </CircleCountDown>
    )
}

const CircleCountDown= ({
                                                       time,
                                                       size=40,
                                                       stroke='#a3cb67',
                                                       onComplete,
                                                       strokeWidth=4,
                                                       strokeLinecap = 'round',

                                                   }) => {
    const radius = size / 2;
    const milliseconds = time * 1000;
    const circumference = size * Math.PI;

    const [countdown, setCountdown] = React.useState(milliseconds);

    const seconds = (countdown / 1000).toFixed();

    const strokeDashoffset = circumference - (countdown / milliseconds) * circumference;

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (countdown > 0) {
                setCountdown(countdown - 10);
            } else {
                clearInterval(interval);
                onComplete && onComplete();
            }
        }, 10);
        return () => clearInterval(interval);
    }, [countdown]);

    return (
        <div className={"root_timer"}>
            <label className={"seconds"}>{seconds}</label>
            <div className={"countDownContainer"}>
                <svg className={"svg_timer"} width={size} height={size}>
                    <circle
                        fill="none"
                        r={radius}
                        cx={radius}
                        cy={radius}
                        stroke={stroke}
                        strokeWidth={strokeWidth}
                        strokeLinecap={strokeLinecap}
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                    />
                </svg>
            </div>
        </div>
    );
};