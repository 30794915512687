import React from "react";
import {T} from "../../utils/translations";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {TimerMaturity, TimerMaturityA, TimerMaturityB, TimerMemoizer} from "./timer";
import {LeaderBoardPretty} from "../../utils/userData";
import {numberPrettier} from "../../utils/numbers";

// in the future retrieve those from backend
export const Crops = {
    wheat: {
        growthTime: 1,
        yieldChance: 0.9,
        minYield: 8,
        maxYield: 12,
        bonusMultiplier: 1.2,  // Example of an extensible property
        yieldTimes: 2, // how many times it can be harvested
    },
    carrot: {
        growthTime: 2,
        yieldChance: 0.85,
        minYield: 1,
        maxYield: 1,
        bonusMultiplier: 1.5,
        yieldTimes: 3,
    },
    potato: {
        growthTime: 3,
        yieldChance: 0.8,
        minYield: 2,
        maxYield: 4,
        bonusMultiplier: 1.3,
        yieldTimes: 3,
    },
    tomato: {
        growthTime: 3,
        yieldChance: 0.75,
        minYield: 3,
        maxYield: 6,
        bonusMultiplier: 1.4,
        yieldTimes: 2,
    },
    rice: {
        growthTime: 3,
        yieldChance: 0.7,
        minYield: 5,
        maxYield: 10,
        bonusMultiplier: 1.6,
        yieldTimes: 1,
    },

};

const CropsPrices = {
    wheat: 10,
    carrot: 20,
    potato: 30,
    tomato: 40,
    rice: 50,
};

const CropsImages = {
    wheat: '🌾',
    carrot: '🥕',
    potato: '🥔',
    tomato: '🍅',
    rice: '🌾',
};

const CropsNames = {
    wheat: 'Wheat',
    carrot: 'Carrot',
    potato: 'Potato',
    tomato: 'Tomato',
    rice: 'Rice',
};

const CropsDescriptions = {
    wheat: 'Wheat is a grass widely cultivated for its seed, a cereal grain which is a worldwide staple food.',
    carrot: 'The carrot is a root vegetable, usually orange in color, though purple, black, red, white, and yellow cultivars exist.',
    potato: 'The potato is a root vegetable native to the Americas, a starchy tuber of the plant Solanum tuberosum.',
    tomato: 'The tomato is the edible berry of the plant Solanum lycopersicum, commonly known as a tomato plant.',
    rice: 'Rice is the seed of the grass species Oryza sativa or less commonly Oryza glaberrima.',
};

const CropsGrowthStages = {
    0: '🌱',
    1: '🌿',
    2: '🌾',
    3: '🌾',
    4: '🌾',
};

const CropsGrowthStagesImages = {
    'wheat': {
        0: '/growthStages/plants/growth_StageWheat0.png',
        1: '/growthStages/plants/growth_StageWheat1.png',
        2: '/growthStages/plants/growth_StageWheat2.png',
        3: '/growthStages/plants/growth_StageWheat3.png',
        4: '/growthStages/plants/growth_StageWheat4.png',
    },
    'carrot': {
        0: '/growthStages/plants/growth_StageCarrot0.png',
        1: '/growthStages/plants/growth_StageCarrot1.png',
        2: '/growthStages/plants/growth_StageCarrot2.png',
        3: '/growthStages/plants/growth_StageCarrot3.png',
        4: '/growthStages/plants/growth_StageCarrot4.png',
    },
    'potato': {
        0: '/growthStages/plants/growth_StagePotato0.png',
        1: '/growthStages/plants/growth_StagePotato1.png',
        2: '/growthStages/plants/growth_StagePotato2.png',
        3: '/growthStages/plants/growth_StagePotato3.png',
        4: '/growthStages/plants/growth_StagePotato4.png',
    },
    'tomato': {
        0: '/growthStages/plants/growth_StageTomato0.png',
        1: '/growthStages/plants/growth_StageTomato1.png',
        2: '/growthStages/plants/growth_StageTomato2.png',
        3: '/growthStages/plants/growth_StageTomato3.png',
        4: '/growthStages/plants/growth_StageTomato4.png',
    },
    'rice': {
        0: '/growthStages/plants/growth_StageRice0.png',
        1: '/growthStages/plants/growth_StageRice1.png',
        2: '/growthStages/plants/growth_StageRice2.png',
        3: '/growthStages/plants/growth_StageRice3.png',
        4: '/growthStages/plants/growth_StageRice4.png',
    },
}

const CropsGrowthStageNames = {
    0: 'Seed',
    1: 'Seedling',
    2: 'Vegetative',
    3: 'Flowering',
    4: 'Mature',
};

const Fruits = {
    apple: {
        growthTime: 5,
        yieldChance: 0.8,
        minYield: 2,
        maxYield: 4,
        bonusMultiplier: 1.3,
        yieldTimes: 2,
    },
    orange: {
        growthTime: 6,
        yieldChance: 0.75,
        minYield: 3,
        maxYield: 6,
        bonusMultiplier: 1.4,
        yieldTimes: 2,
    },
    banana: {
        growthTime: 6,
        yieldChance: 0.7,
        minYield: 5,
        maxYield: 10,
        bonusMultiplier: 1.6,
        yieldTimes: 1,
    },
};
const FruitsPrices = {
    apple: 60,
    orange: 70,
    banana: 80,
};
const FruitsImages = {
    apple: '🍎',
    orange: '🍊',
    banana: '🍌',
};
const FruitsNames = {
    apple: 'Apple',
    orange: 'Orange',
    banana: 'Banana',
};
const FruitsDescriptions = {
    apple: 'An apple is an edible fruit produced by an apple tree.',
    orange: 'The orange is the fruit of various citrus species in the family Rutaceae.',
    banana: 'A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa.',
};
const FruitsGrowthStages = {
    0: '🌱',
    1: '🌿',
    2: '🌳',
    3: '🌳',
    4: '🌳',
};



const FruitsGrowthStagesImages = {
    'apple': {
        0: '/growthStages/fruits/growth_StageApple0.png',
        1: '/growthStages/fruits/growth_StageApple1.png',
        2: '/growthStages/fruits/growth_StageApple2.png',
        3: '/growthStages/fruits/growth_StageApple3.png',
        4: '/growthStages/fruits/growth_StageApple4.png',
    },
    'orange': {
        0: '/growthStages/fruits/growth_StageOrange0.png',
        1: '/growthStages/fruits/growth_StageOrange1.png',
        2: '/growthStages/fruits/growth_StageOrange2.png',
        3: '/growthStages/fruits/growth_StageOrange3.png',
        4: '/growthStages/fruits/growth_StageOrange4.png',
    },
    'banana': {
        0: '/growthStages/fruits/growth_StageBanana0.png',
        1: '/growthStages/fruits/growth_StageBanana1.png',
        2: '/growthStages/fruits/growth_StageBanana2.png',
        3: '/growthStages/fruits/growth_StageBanana3.png',
        4: '/growthStages/fruits/growth_StageBanana4.png',
    },
}

const FruitsGrowthStageNames = {
    0: 'Seed',
    1: 'Seedling',
    2: 'Vegetative',
    3: 'Flowering',
    4: 'Mature',
};



const Animals = {
    cow: {
        growthTime: 6,
        yieldChance: 0.7,
        minYield: 5,
        maxYield: 10,
        bonusMultiplier: 1.6,
        yieldTimes: 1,
    },
    // pig: {
    //     growthTime: 25,
    //     yieldChance: 0.65,
    //     minYield: 6,
    //     maxYield: 12,
    //     bonusMultiplier: 1.7,
    //     yieldTimes: 1,
    // },
    chicken: {
        growthTime: 7,
        yieldChance: 0.6,
        minYield: 7,
        maxYield: 14,
        bonusMultiplier: 1.8,
        yieldTimes: 1,
    },
    bison: {
        growthTime: 9,
        yieldChance: 1,
        minYield: 1,
        maxYield: 1,
        bonusMultiplier: 1.9,
        yieldTimes: 1,
    },
    ox:{
        growthTime: 9,
        yieldChance: 1,
        minYield: 1,
        maxYield: 1,
        bonusMultiplier: 2,
        yieldTimes: 1,
    },
    buffalo:{
        growthTime: 9,
        yieldChance: 1,
        minYield: 1,
        maxYield: 1,
        bonusMultiplier: 2.1,
        yieldTimes: 1,
    },
    unicorn:{
        growthTime: 10,
        yieldChance: 1,
        minYield: 1,
        maxYield: 1,
        bonusMultiplier: 2.2,
        yieldTimes: 1,
    }
};
const AnimalsPrices = {
    cow: 100,
    pig: 110,
    chicken: 120,
    bison: 200,
    ox: 300,
    buffalo: 400,
    unicorn: 20000,
};
const AnimalsImages = {
    cow: '🐄',
    pig: '🐖',
    chicken: '🐓',
    bison: '🦬',
    ox: '🐂',
    buffalo: '🐃',
    unicorn: '🦄',
};
const AnimalsNames = {
    cow: 'Cow',
    pig: 'Pig',
    chicken: 'Chicken',
    bison: 'Bison',
    ox: 'Ox',
    buffalo: 'Buffalo',
    unicorn: 'Unicorn',
};
const AnimalsDescriptions = {
    cow: 'The cow is a domestic animal that is raised for its meat, milk, and hides.',
    pig: 'A pig is any of the animals in the genus Sus, within the even-toed ungulate family Suidae.',
    chicken: 'The chicken is a type of domesticated fowl, a subspecies of the red junglefowl.',
};
const AnimalsGrowthStages = {
    0: '🐣',
    1: '🐥',
    2: '🐓',
    3: '🐓',
    4: '🐓',
};

const AnimalsGrowthStagesImages = {
    'cow': {
        0: '/growthStages/animals/growth_StageCow0.png',
        1: '/growthStages/animals/growth_StageCow1.png',
        2: '/growthStages/animals/growth_StageCow2.png',
        3: '/growthStages/animals/growth_StageCow3.png',
        4: '/growthStages/animals/growth_StageCow4.png',
    },
    'pig': {
        0: '/growthStages/animals/growth_StagePig0.png',
        1: '/growthStages/animals/growth_StagePig1.png',
        2: '/growthStages/animals/growth_StagePig2.png',
        3: '/growthStages/animals/growth_StagePig3.png',
        4: '/growthStages/animals/growth_StagePig4.png',
    },
    'chicken': {
        0: '/growthStages/animals/growth_StageChicken0.png',
        1: '/growthStages/animals/growth_StageChicken1.png',
        2: '/growthStages/animals/growth_StageChicken2.png',
        3: '/growthStages/animals/growth_StageChicken3.png',
        4: '/growthStages/animals/growth_StageChicken4.png',
    },
    'bison': {
        0: '/growthStages/animals/growth_StageBison0.png',
        1: '/growthStages/animals/growth_StageBison1.png',
        2: '/growthStages/animals/growth_StageBison2.png',
        3: '/growthStages/animals/growth_StageBison3.png',
        4: '/growthStages/animals/growth_StageBison4.png',
    },
    'ox': {
        0: '/growthStages/animals/growth_StageOx0.png',
        1: '/growthStages/animals/growth_StageOx1.png',
        2: '/growthStages/animals/growth_StageOx1.png',
        3: '/growthStages/animals/growth_StageOx1.png',
        4: '/growthStages/animals/growth_StageOx3.png',
    },
    'buffalo': {
        0: '/growthStages/animals/growth_StageBuffalo0.png',
        1: '/growthStages/animals/growth_StageBuffalo1.png',
        2: '/growthStages/animals/growth_StageBuffalo2.png',
        3: '/growthStages/animals/growth_StageBuffalo3.png',
        4: '/growthStages/animals/growth_StageBuffalo4.png',
    },
    'unicorn': {
        0: '/growthStages/animals/growth_StageUnicorn0.png',
        1: '/growthStages/animals/growth_StageUnicorn1.png',
        2: '/growthStages/animals/growth_StageUnicorn2.png',
        3: '/growthStages/animals/growth_StageUnicorn3.png',
        4: '/growthStages/animals/growth_StageUnicorn4.png',
    }
}

const AnimalsGrowthStageNames = {
    0: 'Egg',
    1: 'Chick',
    2: 'Juvenile',
    3: 'Adult',
    4: 'Adult',
};
const AnimalsFeed = {
    cow: {
        feedTime: 5,
        feedCost: 20,
        feedYield: 5,
    },
    pig: {
        feedTime: 5,
        feedCost: 20,
        feedYield: 5,
    },
    chicken: {
        feedTime: 5,
        feedCost: 20,
        feedYield: 5,
    },
    bison: {
        feedTime: 5,
        feedCost: 20,
        feedYield: 5,
    },
    ox: {
        feedTime: 5,
        feedCost: 20,
        feedYield: 5,
    },
    buffalo: {
        feedTime: 5,
        feedCost: 20,
        feedYield: 5,
    },
    unicorn: {
        feedTime: 5,
        feedCost: 20,
        feedYield: 5,
    },
};

const AnimalsFeedImages = {
    cow: '🌾',
    pig: '🌾',
    chicken: '🌾',
    bison: '🌾',
    ox: '🌾',
    buffalo: '🌾',
    unicorn: '🌾',

};
const AnimalsFeedNames = {
    cow: 'Hay',
    pig: 'Swill',
    chicken: 'Grain',
    bison: 'Hay',
    ox: 'Hay',
    buffalo: 'Hay',
    unicorn: 'Hay',
};
const AnimalsFeedDescriptions = {
    cow: 'Hay is dried grass used as animal fodder.',
    pig: 'Swill is food for animals, especially kitchen refuse given to pigs.',
    chicken: 'Grain is a small, hard, dry seed, with or without an attached hull or fruit layer, harvested for human or animal consumption.',
};
const AnimalsFeedYields = {
    cow: 5,
    pig: 6,
    chicken: 7,
    bison: 8
};



const retrievePrices = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}games/tapfarm/get-market-prices`)
    const data = await response.json()
    return data.prices;
    // return {
    //     wheat: 10,
    //     carrot: 20,
    //     potato: 30,
    //     tomato: 40,
    //     rice: 50,
    //     apple: 60,
    //     orange: 70,
    //     banana: 80,
    //     cow: 100,
    //     pig: 110,
    //     chicken: 120,
    // }
}

const randomString8 = () => {
    return Math.random().toString(36).substring(2, 10);
}




export const FarmList = ({userData, setUserData}) => {

    const [prices, setPrices] = React.useState({})
    React.useEffect(() => {
        retrievePrices().then(data => {
            setPrices(data)
        })
        const interval = setInterval(() => {
            retrievePrices().then(data => {
                setPrices(data)
            })
        }, 1000)
        return () => clearInterval(interval)
    }, []);



    React.useEffect(()=>{

        const isProduction = process.env.NODE_ENV === 'production'

        const autoGrow = setInterval(() => {
            const newUserData = {...userData}
            if(!userData.game_data) return
            if(userData.game_data.crops){
                Object.keys(userData.game_data.crops).forEach(crop => {
                    const cropData = userData.game_data.crops[crop]
                    const category = 'crops'
                    if(cropData.growthStage <= 4){
                        const timePassed = (Date.now() - cropData.stageStart) / 1000
                        if(timePassed > Crops[crop.split('-')[0]].growthTime){
                            newUserData.game_data[category][crop].growthStage += 1
                            newUserData.game_data[category][crop].stageStart = Date.now()
                            newUserData.game_data[category][crop].completedStageActions = 5
                        }
                    }
                })
            }

            if(userData.game_data.fruits){
                Object.keys(userData.game_data.fruits).forEach(fruit => {
                    const fruitData = userData.game_data.fruits[fruit]
                    const category = 'fruits'
                    if(fruitData.growthStage <= 4){
                        const timePassed = (Date.now() - fruitData.stageStart) / 1000
                        if(timePassed > Fruits[fruit.split('-')[0]].growthTime){
                            newUserData.game_data[category][fruit].growthStage += 1
                            newUserData.game_data[category][fruit].stageStart = Date.now()
                            newUserData.game_data[category][fruit].completedStageActions = 5
                        }
                    }
                })
            }
            if(userData.game_data.animals){
                Object.keys(userData.game_data.animals).forEach(animal => {
                    const animalData = userData.game_data.animals[animal]
                    const category = 'animals'
                    if(animalData.growthStage <= 4){
                        const timePassed = (Date.now() - animalData.stageStart) / 1000
                        if(timePassed > Animals[animal.split('-')[0]].growthTime){
                            newUserData.game_data[category][animal].growthStage += 1
                            newUserData.game_data[category][animal].stageStart = Date.now()
                            newUserData.game_data[category][animal].completedStageActions = 5
                        }
                    }
                })
            }

            setUserData(newUserData)

        },isProduction?1000:1000)
        return ()=>clearInterval(autoGrow)

    },[userData])


    // const [lockButtons, setLockButtons] = React.useState(false)

    const buyItem = (item) => {
        const category= Crops[item] ? 'crops' : Fruits[item] ? 'fruits' : 'animals'
        if (userData.balance >= prices[item]) {
             fetch(`${process.env.REACT_APP_BACKEND_URL}games/tapfarm/new-trade?action=buy&item=${item}`).then(response => response.json()).then(data => {
                 setPrices({
                        ...prices,
                        [item]: data.price

                 })
             })
            setUserData({...userData,
                balance: Number(userData.balance) - Number(prices[item]),

            game_data: {
                ...userData.game_data,
               // add item to correct category
                [category]: {
                    ...userData.game_data[category],
                    [item+'-'+randomString8()]: {
                        growthStage: 0,
                        stageStart: Date.now(),
                        yields: 0,
                        completedStageActions: 0,
                        boughtPrice: prices[item],
                        activeStageActions: 0,
                    }
            }
            }
            })
        }
    }
    const sellItem = (item,itemId) => {

        console.log('selling: ', itemId)
        const category= Crops[item] ? 'crops' : Fruits[item] ? 'fruits' : 'animals'

             fetch(`${process.env.REACT_APP_BACKEND_URL}games/tapfarm/new-trade?action=sell&item=${item}`).then(response => response.json()).then(data => {
                 setPrices({
                        ...prices,
                        [item]: data.price

                 })
             })
        // remove item from correct category
            const currentUserData= {...userData}
            delete currentUserData.game_data[category][itemId]
            setUserData({...currentUserData,
            balance: Number(currentUserData.balance) + Number(prices[item])
            })
        }

        // check every second if any item is ready for next stage


    const [displayLists, setDisplayLists] = React.useState([])

const [displayCategory, setDisplayCategory] = React.useState('crops')

    const boosts = [
        {image:'boost_AutoFeedWater.png', name: 'Auto feed & auto Water', price: 500},
        {image:'boost_DoubleProfit.png', name: 'Double Profit - 10mins', price: 1000},
    ]

    const [showPopup, setShowPopup] = React.useState(false)

    const [ReadyToSell, setReadyToSell] = React.useState([])

    React.useEffect(()=>{

        // find all items that are ready to sell
        const readyToSell = {}
        if(userData.game_data.crops){
            // sort by lowest boughtPrice
            const sortedCrops =
                Object.keys(userData.game_data.crops).sort((a,b)=>Number(userData.game_data.crops[a].boughtPrice) - Number(userData.game_data.crops[b].boughtPrice))
            sortedCrops.forEach(crop => {
                if(userData.game_data.crops[crop].growthStage >= 4){
                    readyToSell[crop.split('-')[0]] ={
                        category: 'crops',
                        id: crop,
                        quantity:(readyToSell[crop.split('-')[0]]?.quantity||0)+1

                    }
                }
            })

        }

        if(userData.game_data.fruits){
            // sort by lowest boughtPrice
            const sortedFruits =
                Object.keys(userData.game_data.fruits).sort((a,b)=>Number(userData.game_data.fruits[a].boughtPrice) - Number(userData.game_data.fruits[b].boughtPrice))
            sortedFruits.forEach(fruit => {
                if(userData.game_data.fruits[fruit].growthStage >= 4){
                    readyToSell[fruit.split('-')[0]] ={
                        category: 'fruits',
                        id: fruit,
                        quantity:(readyToSell[fruit.split('-')[0]]?.quantity||0)+1

                    }
                }
            })


        }


        if(userData.game_data.animals){
            // sort by lowest boughtPrice
            const sortedAnimals =
                Object.keys(userData.game_data.animals).sort((a,b)=>Number(userData.game_data.animals[a].boughtPrice) - Number(userData.game_data.animals[b].boughtPrice))
            sortedAnimals.forEach(animal => {
                if(userData.game_data.animals[animal].growthStage >= 4){
                    readyToSell[animal.split('-')[0]] ={
                        category: 'animals',
                        id: animal,
                        quantity:(readyToSell[animal.split('-')[0]]?.quantity||0)+1

                    }
                }
            })


        }


console.log('Ready to sell:' ,readyToSell)
        setReadyToSell(readyToSell)


    },[userData])

    return (
<>
        <FarmGrid userData={userData} setUserData={setUserData} category={displayCategory}/>
        <BuyerMenu userData={userData} setUserData={setUserData} prices={prices} category={displayCategory}  buyItem={buyItem} setShowPopup={setShowPopup} />
    <div className={"wrapper-farming-all"}>
        {/*<h1>Farm List</h1>*/}
        <div className={'task-list-item leaderboard tabs'}>

            {/*<div style={{flex: 1}} className={displayCategory === 'boost' ? 'active' : ''}*/}
            {/*     onClick={() => setDisplayCategory('boost')}*/}
            {/*>Boost*/}
            {/*    <div className={'flash x'}></div>*/}
            {/*</div>*/}

            <div style={{flex: 1}} className={displayCategory === 'crops' ? 'active' : ''}
                 onClick={() => setDisplayCategory('crops')}
            >{T('crops')}</div>
            <div style={{flex: 1}} className={displayCategory === 'fruits' ? 'active' : ''}
                 onClick={() => setDisplayCategory('fruits')}
            >{T('fruits')}</div>
            <div style={{flex: 1}} className={displayCategory === 'animals' ? 'active' : ''}
                 onClick={() => setDisplayCategory('animals')}
            >{T('animals')}</div>
        </div>


        {(displayCategory === 'boost') && (
            <div style={{marginBottom: '50px'}} className={"wrapper-list-product"}>
                <span>Not Available</span>
                {boosts.map((boost, index) => (
                    <div className={['task-list-item leaders disabled',
                        // userIndex === index && 'golden'
                    ].join(' ')} key={index}
                        // ref={userIndex === index ? myRef : null}

                    >
                        <div style={{
                            flex: 1,
                            // fontSize: index + 1 === 1 ? '30px' : index + 1 === 2 ? '25px' : index + 1 === 3 ? '20px' : '15px'
                        }}>
                            <img src={boost.image} alt={'boost'} style={{boxShadow: 'none'}}/>
                        </div>
                        <div style={{flex: 1, fontWeight: 'bold'}}>{boost.name}</div>
                        <div style={{flex: 1}}>{numberPrettier(boost.price)} 🪙</div>
                    </div>

                ))}
            </div>
        )}


        <div className={['miner-popup barn', showPopup ? 'visible' : ''].join(' ')}>
            {(showPopup) && (
                <div className={'miner-popup-inner'}>
                    <div className={'miner-popup-inner-close'} onClick={() => setShowPopup(false)}>X</div>
                    {(userData.game_data.crops || userData.game_data.fruits || userData.game_data.animals) && (
                        <div className={'wrapper-produce-for-sale'}>
                        {Object.keys(ReadyToSell).map(item => {
                            const crop = ReadyToSell[item].id.split('-')[0]
                            if (!userData.game_data[ReadyToSell[item].category][ReadyToSell[item].id] || userData.game_data[ReadyToSell[item].category][ReadyToSell[item].id].growthStage < 4) {
                                return
                            }
                            return (
                                <div key={item} className={"produce-for-sale"}>
                                    <div>
                                    <img
                                        src={(ReadyToSell[item].category ==='crops'?CropsGrowthStagesImages: ReadyToSell[item].category==='fruits'?FruitsGrowthStagesImages:AnimalsGrowthStagesImages)[crop][4]}


                                        alt={'item on grid'}/>
                                        <sup>
                                            x{ReadyToSell[item].quantity}
                                        </sup>
                                    </div>
                                    <div style={{
                                        textAlign:'left',
                                        fontSize:'0.9em',
                                    }}>

                                        {prices[item]}🪙 <sup style={{
                                            color:userData.game_data[ReadyToSell[item].category][ReadyToSell[item].id].boughtPrice - prices[item]>0?'green':'red'
                                    }}>{numberPrettier(userData.game_data[ReadyToSell[item].category][ReadyToSell[item].id].boughtPrice - prices[item])}</sup>
                                    </div>
                                    <button onClick={() => sellItem(crop,ReadyToSell[item].id)}>{T('sell')}</button>
                                </div>
                            )

                        })}
                        </div>
                    )}

                </div>
            )}
        </div>


    </div>
</>
    )

}

export const FarmGrid = ({userData, setUserData, category}) => {
    category = category === 'boost' ? 'crops' : category
    const gridImage = category === 'crops' ? '/gridFarm.jpeg' : category === 'fruits' ? '/gridOrchard.jpeg' : '/gridPasture.jpeg';


    return (
        <div>
            <div className={"wrapper-farming-grid"}>
                <img src={gridImage} alt={'farm grid'}/>
                <div className={["farm-crops-on-grid", category].join(' ')}>
                    {userData.game_data[category] && Object.keys(userData.game_data[category]).map(item => {
                        const crop = item.split('-')[0]
                        if (userData.game_data[category][item].growthStage >= 4) {
                            return
                        }
                        return (
                            <div key={item} className={["crop-on-grid", category].join(' ')}>
                                <img
                                    src={(category === 'crops' ? CropsGrowthStagesImages : category === 'fruits' ? FruitsGrowthStagesImages : AnimalsGrowthStagesImages)[crop][userData.game_data[category][item].growthStage]}
                                    alt={'item on grid'}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )

}


export const BuyerMenu = ({userData, setUserData,category,prices, buyItem, setShowPopup}) => {
category= category==='boost'?'crops':category
    return(
        <div className={"floating-buyers-menu"}>
            <div key={'Barn'} className={"buyer-item sell"}>
                {/*<h3>*/}
                <div className={'flash xx'}/>
                <img
                    src={'/barn_Stowage.png'}
                    alt={'barn stowage'}/>
                {/*</h3>*/}
                {/*<h3>{item}</h3>*/}
                {/*<h4>{prices[item]}🪙</h4>*/}
                <button
                    onClick={() => setShowPopup(true)}
                    >
                        {T('sell')}</button>
            </div>

            {Object.keys(category === 'crops' ? Crops : category === 'fruits' ? Fruits : Animals).map(item => (
                <div key={item} className={["buyer-item", category].join(' ')}>
                    {/*<h3>*/}
                    <img
                        src={(category === 'crops' ? CropsGrowthStagesImages : category === 'fruits' ? FruitsGrowthStagesImages : AnimalsGrowthStagesImages)[item][4]}
                        alt={item}/>
                    {/*</h3>*/}
                    {/*<h3>{item}</h3>*/}
                    <h4>{prices[item]}🪙</h4>
                    <button onClick={() => buyItem(item)}>{T('buy')}</button>
                </div>
            ))}
        </div>
    )
}
