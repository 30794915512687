import {useInitData} from "@vkruglikov/react-telegram-web-app";

export const Levels = {
    1: 'Sprout Saver',
    2: 'Seedling Stacker',
    3: 'Green Thumb Guru',
    4: 'Harvest Hero',
    5: 'Barnyard Boss',
    6: 'Pasture Prodigy',
    7: 'Field of Fortune',
};

export const LevelsThreshold = {
    1: 8000,
    2: 15000,
    3: 300000,
    4: 50000000,
    5: 1000000000,
    6: 5000000000,
    7: 50000000000,
};

export const LevelsThresholdPretty = {
    1: '8K',
    2: '15K',
    3: '300K',
    4: '50M',
    5: '1B',
    6: '5B',
    7: '50B',
}
export const GetLevel = (balance) => {
    for (let i = 7; i >= 1; i--) {
        if (balance > LevelsThreshold[i]) {
            return i;
        }
    }
    return 1;
}


export const GetUserData =  () => {
    const userData = {
        username:'',
        username_original:'',
        userId:'',
        is_wallet:false,
        balance:1000,
    };
    try {
        const [initDataUnsafe, initData] = useInitData();
        userData.username=initDataUnsafe.user.username;
        if(initDataUnsafe.user.username===null||initDataUnsafe.user.username==='' || initDataUnsafe.user.username===undefined || initDataUnsafe.user.username==='undefined'){
            userData.username = initDataUnsafe.user.first_name
        }
        userData.userId=initDataUnsafe.user.id

    }catch (e){
        console.log('telegram error: ',e)
        const queryParameters = new URLSearchParams(window.location.search)
        userData.username = queryParameters.get("eth_address")
        userData.username_original = queryParameters.get("eth_address")
        userData.is_wallet=true
        userData.username = PrettierUserName(userData)
    }
    


    return userData;
}

export const PrettierUserName = (userData) => {
    if(userData?.active_nickname){
        return userData.nickname
    }
    if(userData.is_wallet){
        return userData.username_original.slice(0, 6) + '...' + userData.username_original.slice(-4)
    }
    return userData.username.split(' ')[0]
}

export const LeaderBoardPretty = (username) => {
    if(username.length < 10){
        return username
    }else{
        return username.slice(0, 6) + '...' + username.slice(-4)

    }
}

export const GetServerUserData = async (userData) => {
    const username = userData.is_wallet ? userData.username_original : userData.username
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}games/get-user-data?gameId=3&address=${username}&is_wallet=${userData.is_wallet}`)
    const data = await response.json()
    if(data.error) return null
    data.userData.username = PrettierUserName({...userData, ...data.userData})
    if(!data.userData?.game_data?.level){
        if(!data.userData.game_data) data.userData.game_data = {}
        data.userData.game_data.level = 1;
        data.userData.game_data.xp = 0;
    }
    return data
}

export const UpdateServerUserData= async (userData) =>{
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}games/tapfarm/update-user-data`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
    })
    const data = await response.json()
    if(data.error) return null
    return data
}