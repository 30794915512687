import {T} from "../utils/translations";


export const AirDrop = ({userData, setUserData}) => {

    return (
        <div>
            <div>
                <div className={"bnf3-wrapper"}>
                    <div className={"bnf3-token"}>
                        <img src={"/Airdrop_BNF3Coin.png"} alt={"airdrop"}/>
                        <img src={"/farm_home.png"} alt={"boss"} className={"bnf3-token-boss"}/>
                    </div>
                </div>
                <h1>Airdrop</h1>
                <p style={{fontSize:'18px',fontWeight:"bolder"}}>1,000,000 BNF3 Airdrop</p>

                <div className={"my-points"}>
                    <span>
                        My Points:
                    </span>
                    <br/>
                    <span>
                        **TBA**
                    </span>
                </div>
                <div style={{textAlign:"left", padding:'10px',fontSize:'16px'}}>
                    <br/>
                    <h3 style={{marginBottom:'5px'}}>
                    {T('CountingAirdrop')}
                        </h3>
                    <br/>
                        {T('CountingAirdrop1')}
                    <br/>
                        {T('CountingAirdrop2')}
                    <br/>
                        {T('CountingAirdrop3')}
                </div>
            </div>
        </div>
    )
}