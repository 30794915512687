import React from "react";
import {TapCoinButton} from "../components/tapCoins";
import {FarmList} from "../components/farmList";
import {HeaderHome} from "../components/headerHome";


export const Home = ({userData, setUserData, setActivePage}) => {


    return (
        <div>
            <HeaderHome userData={userData}  setActivePage={setActivePage}/>
            <div className={'home-pretty-top has_farm'}>
                <FarmList userData={userData} setUserData={setUserData}/>
            </div>
            <TapCoinButton userData={userData} setUserData={setUserData}/>
        </div>
    )
}