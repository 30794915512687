import React from "react";
import {HeaderHome} from "../components/headerHome";
import {numberPrettier} from "../utils/numbers";
import {TapCoinButton} from "../components/tapCoins";

const tasks = [
    {
        id: 2,
        name: "Follow BNF3 On Twitter",
        description: "Stay in the loop and get the latest updates! Follow BNF3 on Twitter to catch all the news, tips, and exclusive rewards. Plus, you'll earn XP and boost your farm just for joining the conversation. Don't miss out—tap that follow button now!",
        reward: 40000,
        image: '/task_FollowTwitter.png',
        url: 'https://x.com/BNF3Wallet'
    },
    {
        id: 3,
        name: "Join BNF3 Telegram",
        description: "Join the BNF3 Telegram community and connect with fellow farmers! Get insider tips, exclusive updates, and be the first to know about special events. Earn XP and grow your farm while you chat and share with others. Tap to join and let's keep the farm buzzing!",
        reward: 5000,
        image: '/task_JoinTelegram.png',
        url: 'https://t.me/BNF3Wallet'
    },

];
const dailyTasks = [
    {
        id: 1,
        name: "Daily Check-in",
        description: "Description 3",
        reward: 1000,
    },
    {
        id: 2,
        name: "Daily Check-in 2",
        description: "Description 4",
        reward: 5000,
    },
    {
        id: 3,
        name: "Daily Check-in 3",
        description: "Description 5",
        reward: 10000,
    },
    {
        id: 4,
        name: "Daily Check-in 4",
        description: "Description 6",
        reward: 15000,
    },
    {
        id: 5,
        name: "Daily Check-in 5",
        description: "Description 7",
        reward: 20000,
    },
    {
        id: 6,
        name: "Daily Check-in 6",
        description: "Description 8",
        reward: 500000,
    },
    {
        id: 7,
        name: "Daily Check-in 7",
        description: "Description 9",
        reward: 3000000,
    }

];

export default function Tasks({userData,setUserData, setActivePage}) {


    const [showPopup, setShowPopup] = React.useState(false);

    const [showPopupTask, setShowPopupTask] = React.useState(false);
    const [popupTaskData, setPopupTaskData] = React.useState(null);

    const [dailyCheckin, setDailyCheckin] = React.useState(0);
    const [checkedIn, setCheckedIn] = React.useState(false);
    const [lastCheckin, setLastCheckin] = React.useState(userData.game_data?.streak||-1);

    React.useEffect(()=>{

        if(userData.game_data.streak && userData.game_data.streak === 7){
            setLastCheckin(-1)
            return;
        }

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        if(userData.game_data.last_checkin && userData.game_data.last_checkin === new Date().toDateString()){
            setCheckedIn(true)
            setLastCheckin(userData.game_data.streak)
            return
        }
        if(userData.game_data.last_checkin && userData.game_data.last_checkin !== yesterday.toDateString()){
            setLastCheckin(-1)
            return
        }

    },[userData])

    const handleTask = () => {
        window.open(popupTaskData.url, "_blank")
        if(userData.game_data.tasks && userData.game_data.tasks.includes(popupTaskData.id)){
            alert("You have already completed this task");
            return;
        }
        setUserData({
            ...userData,
            balance: userData.balance + popupTaskData.reward,
            game_data: {
                ...userData.game_data,
                tasks: [
                    ...userData.game_data?.tasks||[],
                    popupTaskData.id
                ]
            }
        });
    }

    const handleDailyCheckin = () => {
// check if user has already checked in today
        if (userData.game_data.last_checkin === new Date().toDateString()) {
            alert("You have already checked in today");
            return;
        }

        // check if user has checked in yesterday
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (userData.game_data.last_checkin === yesterday.toDateString()) {
            // user has checked in yesterday
            // increment the streak

            setUserData({
                ...userData,
                balance: userData.balance + dailyTasks[userData.game_data?.streak||1].reward,
                game_data: {
                    ...userData.game_data,
                    last_checkin: new Date().toDateString(),
                    streak: (userData.game_data?.streak||1) + 1
                }
            });
        } else {
            // user has not checked in yesterday
            // reset the streak
            setUserData({
                ...userData,
                balance: userData.balance + dailyTasks.find(task => task.id === dailyCheckin).reward,
                game_data: {
                    ...userData.game_data,
                    last_checkin: new Date().toDateString(),
                    streak: 1
                }
            });


        }
    }


    return (
        <div>
            <HeaderHome userData={userData} setActivePage={setActivePage}/>
            <div className={'home-pretty-top golden'}>

                <div>
                    {tasks.map((task) => (
                        <div key={task.id} className={["task-list-item",
                            (userData.game_data.tasks && userData.game_data.tasks.includes(task.id)) && 'done'
                        ].join(' ')}
                             onClick={() => {
                                 if (userData.game_data.tasks && userData.game_data.tasks.includes(task.id)) return
                                 setPopupTaskData(task)
                                 setShowPopupTask(true)
                             }}>
                            {
                                // (lastCheckin===1 && lastCheckin===index-1) ||
                                (userData.game_data.tasks && userData.game_data.tasks.includes(task.id)) && (
                                <div className={"task-list-item-done"}>
                                    &#10003;
                                </div>
                            )}
                            <h4>{task.name}</h4>
                            {/*<p>{task.description}</p>*/}
                            <h3> {numberPrettier(task.reward)} 🪙</h3>
                        </div>
                    ))}
                    More coming soon
                </div>
                <h2>Daily Tasks {' '}
                    <span style={{fontSize: '20px'}}>+{numberPrettier(dailyTasks.reduce(
                        (a, b) => a + b.reward, 0
                    ))} 🪙</span></h2>

                {/*)} </h2>*/}
                <div>
                    {dailyTasks.map((task, index) => {
                            // console.log('last checkedIn: ',lastCheckin, task.id, lastCheckin >= index+1,
                            //     lastCheckin ===-1 && index !== 0,
                            //     checkedIn,
                            //     lastCheckin < index-1
                            // )
                            return (
                                <div key={task.id} className={["task-list-item",
                                    // add class done if current checkin is not valid
                                    (lastCheckin >= index + 1) && 'done',
                                    (lastCheckin >= 1 && lastCheckin <= index - 1) && 'done',
                                    // add class done if current checkin is not valid
                                    (lastCheckin === -1 && index !== 0) && 'done',
                                    (checkedIn) && 'done',
                                    // don't allow checkin in the future
                                    (lastCheckin < index - 1) && 'done',

                                    //     (
                                    //     (lastCheckin ===-1 && index !== 0) || (
                                    //     // lastCheckin <= index ||
                                    //     lastCheckin === index ||
                                    //     !checkedIn)
                                    // ) ? 'done' : ''

                                ].join(' ')}
                                     onClick={() => {
                                         if (lastCheckin >= index + 1) return
                                         if (checkedIn) return
                                         if (lastCheckin >= 1 && lastCheckin <= index - 1) return
                                         // add class done if current checkin is not valid
                                         if (lastCheckin === -1 && index !== 0) return;
                                         if (lastCheckin < index - 1) return;
                                         setDailyCheckin(task.id)
                                         setShowPopup(true)
                                     }}
                                >
                                    <img src={'/tasks_CheckIn.png'} alt={'checkin'}/>
                                    {(
                                        // (lastCheckin===1 && lastCheckin===index-1) ||
                                        (lastCheckin >= index + 1)) && (
                                        <div className={"task-list-item-done"}>
                                            &#10003;
                                        </div>
                                    )}
                                    <h4>{task.name}</h4>
                                    {/*<p>{task.description}</p>*/}
                                    <h3> {numberPrettier(task.reward)} 🪙</h3>
                                </div>
                            )
                        }
                    )}
                </div>

                <hr style={{color:'gray',
                    backgroundColor:'gray',
                    height:1,
                    width:'100%',
                    border:'none'
                }}/>

                <TapCoinButton userData={userData} setUserData={setUserData} type={"large"} />

            </div>

            <div className={['miner-popup', showPopup ? 'visible' : ''].join(' ')}>
                {(showPopup) && (
                    <div className={'miner-popup-inner'}>
                        <div className={'miner-popup-inner-close'} onClick={() => setShowPopup(false)}>X</div>
                        <div className={'miner-popup-inner-title'}>Daily Check-in</div>
                        <div className={'miner-popup-inner-image'}>
                            <img src={'/tasks_CheckIn.png'} alt={'wow wow'}/>
                        </div>
                        <div className={'miner-popup-inner-description'}>Moo-ve on over for your daily check-in!
                        </div>
                        <div className={"miner-popup-inner-profit-per-hour"}>
                            <br/>
                            +{numberPrettier(dailyTasks.find(
                                (task, index) => task.id===dailyCheckin
                        ).reward)}🪙
                        </div>
                        <div className={'miner-popup-inner-buy'}>
                            <button onClick={() =>{
                                setShowPopup(false)
                                handleDailyCheckin()
                            }}>CheckIn!🪙
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className={['miner-popup', showPopupTask ? 'visible' : ''].join(' ')}>
                {(showPopupTask) && (
                    <div className={'miner-popup-inner'}>
                        <div className={'miner-popup-inner-close'} onClick={() => setShowPopupTask(false)}>X</div>
                        <div className={'miner-popup-inner-title'}>{popupTaskData.name}</div>
                        <div className={'miner-popup-inner-image'}>
                            <img src={popupTaskData.image} alt={'wow wow'}/>
                        </div>
                        <div className={'miner-popup-inner-description'}>{popupTaskData.description}
                        </div>
                        <div className={"miner-popup-inner-profit-per-hour"}>
                            <br/>
                            +{numberPrettier(popupTaskData.reward)}🪙
                        </div>
                        <div className={'miner-popup-inner-buy'}>
                            <button onClick={() => {
                                setShowPopupTask(false)
                                handleTask()
                            }}>Complete!🪙
                            </button>
                        </div>
                    </div>
                )}


            </div>


        </div>
    );
}