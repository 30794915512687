import React from "react";
import {GetMinerProfit, miners} from "../../utils/minerItems";
import {GetLevel, Levels, LevelsThreshold, LevelsThresholdPretty} from "../../utils/userData";


export const HeaderHome = ({userData, setActivePage}) => {
    // rounded userData.username and userData.balance with a coin emoji in front

    return (
        <div style={{
            marginTop:'50px',
        }}>
            {' '}
            <div style={
                {
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    textAlign: 'justify',
                    position: 'fixed',
                    top: 0,
                    background: 'linear-gradient(rgb(21, 21, 21) 30%, rgb(21 21 21 / 70%) 55%, rgb(21 21 21 / 50%) 75%)',
                    width: 'calc(100vw - 20px)',
                    padding: '10px',
                    borderBottomRightRadius: '20px',
                    borderBottomLeftRadius: '20px',
                    zIndex:5,
                }
            }>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                }}>
                    <p style={
                        {
                            fontSize: '1em',
                            margin: 0
                        }

                    }>
                        {userData.username}
                    </p>
                    <p style={{margin: 0, fontSize: '19px'}}>🪙{Number(userData.balance).toFixed(2)}</p>
                </div>

                <div style={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'space-between',
                    alignContent:'center',
                    alignItems:'center',
                }}>
                   <div onClick={()=>{
                          setActivePage('Leaderboard')
                   }}>
                        <ProgressBar userData={userData}/>
                   </div>
                    <div className={"miners-profit"} onClick={()=>{
                        setActivePage('Miner')
                    }}>
                        <span className={"miners-profit-first"}>Hourly</span>
                        <span className={"miners-profit-second"}>🪙{' '}{
                            userData.game_data.miners ?
                                Object.keys(userData.game_data.miners).map(minerId => {
                                    return GetMinerProfit({
                                            ...miners.find(m => m.id === Number(minerId)),
                                            level: userData.game_data.miners[minerId].level
                                        }
                                        )
                                }).reduce((a,b)=>a+b,0).toFixed(2)
                                : 0

                        }</span>
                        <span style={{fontWeight:"bolder",paddingLeft:'10px'}}>?</span>
                    </div>
                </div>


            </div>
        </div>
    )

}

// ProgressBar component
export const ProgressBar = ({userData}) => {
    // progress bar with userData.balance






    const level = GetLevel(userData.balance);
    const levelProgress = (userData.balance / LevelsThreshold[level+1]) * 100;

    // progress bar with userData.balance
    return (
        <div style={{textAlign:"justify"}}>
            <span style={{fontSize:'13px'}}>
                {Levels[level]} ->
            </span>
            <div style={
                {
                    width: '150px',
                    height: '15px',
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    borderRadius: '10px',
                    marginTop: '1px',
                    marginBottom: '10px'
                }
            }>
                <div style={
                    {
                        width: `${levelProgress}%`,
                        height: '100%',
                        backgroundColor: 'rgba(109,234,127,0.8)',
                        borderRadius: '10px'
                    }
                }>
                    <p className={'over-level-bar'}>
                        {/*{levelProgress.toFixed(2)}%*/}
                        {LevelsThresholdPretty[level+1] +'🪙'}
                    </p>
                </div>
            </div>
        </div>
    )

}
