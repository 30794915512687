

const languages = ['en', 'id', 'my'];

export const T = (key) => {
    let lang = navigator.language || navigator.userLanguage;
    lang = lang.split('-')[0];
    if (!languages.includes(lang)) {
        lang = 'en';
    }
    return translations[lang][key];

}


const translations = {
    en: {
        crops: 'Crops',
        fruits: 'Fruits',
        animals: 'Animals',
        farmList: 'Farm List',
        buy: 'Buy',
        sell: 'Sell',
        price: 'Price',
        growthStage: 'Growth Stage',
        stageStart: 'Stage Start',
        yields: 'Yields',
        level: 'Level',
        score: 'Score',
        balance: 'Balance',
        inventory: 'Inventory',
        items: 'Items',
        item: 'Item',
        name: 'Name',
        quantity: 'Quantity',
        Farm: 'Farm',
        Miner: 'Miner',
        Tasks: 'Tasks',
        AirDrop: 'AirDrop',
        CountingAirdrop: 'All Your actions in game count towards a higher Points score.' ,
        CountingAirdrop1: '- The Total you harvest, the more market price impact you cause.',
        CountingAirdrop2: '- Daily Tasks, Miner unlocks.',
        CountingAirdrop3: '- Leveling up, and more.',
    },
    id: {
        crops: 'Tanaman',
        fruits: 'Buah',
        animals: 'Hewan',
        farmList: 'Daftar Pertanian',
        buy: 'Beli',
        sell: 'Jual',
        price: 'Harga',
        growthStage: 'Tahap Pertumbuhan',
        stageStart: 'Mulai Tahap',
        yields: 'Hasil',
        level: 'Level',
        score: 'Skor',
        balance: 'Saldo',
        inventory: 'Inventaris',
        items: 'Barang',
        item: 'Barang',
        name: 'Nama',
        quantity: 'Kuantitas',
        Farm: 'Pertanian',
        Miner: 'Penambang',
        Tasks: 'Tugas',
        AirDrop: 'AirDrop',
        CountingAirdrop: 'Semua tindakan Anda dalam permainan berkontribusi pada skor Poin yang lebih tinggi.',
        CountingAirdrop1: '- Total panen Anda, semakin besar dampak harga pasar yang Anda sebabkan.',
        CountingAirdrop2: '- Tugas Harian, Miner membuka kunci.',
        CountingAirdrop3: '- Leveling up, dan lainnya.',
    },
    my: {
        crops: 'Tanaman',
        fruits: 'Buah',
        animals: 'Haiwan',
        farmList: 'Senarai Ladang',
        buy: 'Beli',
        sell: 'Jual',
        price: 'Harga',
        growthStage: 'Tahap Pertumbuhan',
        stageStart: 'Mula Tahap',
        yields: 'Hasil',
        level: 'Tahap',
        score: 'Markah',
        balance: 'Baki',
        inventory: 'Inventori',
        items: 'Barang',
        item: 'Barang',
        name: 'Nama',
        quantity: 'Kuantiti',
        Farm: 'Ladang',
        Miner: 'Penambang',
        Tasks: 'Tugas',
        AirDrop: 'AirDrop',
        CountingAirdrop: 'Semua tindakan anda dalam permainan menyumbang kepada skor Mata yang lebih tinggi',
        CountingAirdrop1: '- Total panen Anda, semakin besar dampak harga pasar yang Anda sebabkan.',
        CountingAirdrop2: '- Tugas Harian, Miner membuka kunci.',
        CountingAirdrop3: '- Leveling up, dan lainnya.',
    },
}