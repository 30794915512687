import React from "react";

export const TapCoinButton = ({userData,setUserData, type="float"}) => {
let className = "tap-coin-earn"
    if(type==='large') className = "tap-coin-earn-large"

    const tap = () => {
        const chance = Math.random()
        if(chance > 0.90) {
            return setUserData({
                ...userData,
                balance: userData.balance + 2
            })
        }
        if(chance > 0.65) {
            return setUserData({
                ...userData,
                balance: userData.balance + 1
            })
        }
        if(chance > 0.60) {
            return setUserData({
                ...userData,
                balance: userData.balance + 1.5
            })
        }
        if(chance > 0.40) {
            return setUserData({
                ...userData,
                balance: userData.balance + 0.9
            })
        }
        if(chance > 0.30) {
            return setUserData({
                ...userData,
                balance: userData.balance + 0.8
            })
        }
        if(chance > 0) {
            return setUserData({
                ...userData,
                balance: userData.balance + 0.3
            })
        }
    }

    return (
        <div className={className}>
            <button onClick={tap}>🪙</button>
            <br/>
            <span>Tap & Earn</span>
        </div>
    )
}