import React from "react";
import {HeaderHome} from "../components/headerHome";
import {numberPrettier} from "../utils/numbers";
import {TapCoinButton} from "../components/tapCoins";
import {GetLevel, LeaderBoardPretty, Levels, LevelsThreshold, LevelsThresholdPretty} from "../utils/userData";

const levelImages = {
    1: '/levels/level_SproutSaver.png',
    2: '/levels/level_SeedlingStacker.png',
    3: '/levels/level_GreenThumbGuru.png',
    4: '/levels/level_HarvestHero.png',
    5: '/levels/level_BarnyardBoss.png',
    6: '/levels/level_PastureProdigy.png',
    7: '/levels/level_FieldOfFortune.png',
}

export const Leaderboard = ({userData, setActivePage}) => {

    const [leaderboard, setLeaderboard] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const [level, setLevel] = React.useState(1)

    const [userIndex, setUserIndex] = React.useState(-1)
    const myRef=React.useRef(null)

    React.useEffect(()=>{
        //find user in leaderboard
        if(leaderboard.length>0) {
            const userIndex = leaderboard.findIndex(user => user.userid === userData.userid)
            if (userIndex !== -1) {
                setUserIndex(userIndex)
                console.log('userIndex:', userIndex)
                // user.username = userData.username
                // user.balance = userData.balance
                // setLeaderboard([...leaderboard.slice(0,userIndex),user,...leaderboard.slice(userIndex+1)])
            }else{
                setUserIndex(-1)
                // setMyRef(null)
            }
        }else{
            // setMyRef(null)
            setUserIndex(-1)
        }
    },[leaderboard,userData])

    const executeScroll = () => myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    })

    React.useEffect(()=>{
        const userLevel =GetLevel(userData.balance)
        console.log('userlevel: ',userLevel)
        setLevel(userLevel)

        const maximumBalance = userLevel===1?LevelsThreshold[1]: userLevel===7?1000000000000000000:  LevelsThreshold[userLevel+1];
        const minimumBalance = userLevel===1?0: LevelsThreshold[userLevel]||0;
        getLeaderboard(maximumBalance,minimumBalance)
    },[])

    const getLeaderboard = async (max_balance,min_balance) => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_BACKEND_URL}games/tapfarm/get-leaderboard?3&max_balance=${max_balance}&min_balance=${min_balance}`).then(response => response.json()).then(data => {
            // console.log('leaderboard data:', data)
            setLeaderboard(data.leaderboard)
            setLoading(false)
        }).catch(e => {
            console.log('error getting leaderboard', e)
            setLoading(false)
        });
    }


    return (
        <div>
            <HeaderHome userData={userData} setActivePage={setActivePage}/>
            <div className={'home-pretty-top golden'}>

                <div>
                    <div className={"level-header"}>
                        <div style={{flex:1}} onClick={()=>{
                            if(level===1 ) return
                            const newLevel=level-1;
                            getLeaderboard(
                                newLevel===1? LevelsThreshold[1]:newLevel===7?1000000000000000000:LevelsThreshold[newLevel+1],
                                newLevel===1?0:LevelsThreshold[newLevel]||0

                            )

                            setLevel(newLevel)
                        }}>
                            <span style={{lineHeight:'200px',fontWeight:'bolder',fontSize:'20px'}}>
                                {'<'}
                            </span>
                        </div>
                        <img src={levelImages[level]} alt={'level'} style={{flex:2}} />
                        <div style={{flex: 1}}
                             onClick={()=>{
                                 if( level===7) return
                                 const newLevel=level+1;
                                 getLeaderboard(
                                     newLevel===1? LevelsThreshold[1]:newLevel===7?1000000000000000000:LevelsThreshold[newLevel+1],
                                     newLevel===1?0:LevelsThreshold[newLevel]||0

                                 )

                                 setLevel(newLevel)
                             }}
                        >

                            <span style={{lineHeight: '200px',fontWeight:'bolder',fontSize:'20px'}}

                            >
                                {'>'}
                            </span>
                        </div>
                    </div>
                    <h2>{Levels[level]}</h2>
                    <h4>{level===1?'+0':  '+'+LevelsThresholdPretty[level]}🪙</h4>
                        <div className={'leaderboard'}>
                            <div className={'task-list-item leaderboard'}>
                                <div style={{flex:1}}>Rank</div>
                                <div style={{flex:1}}>Username</div>
                                <div style={{flex:1}}>Balance</div>
                            </div>
                            {leaderboard.length>0 ? (
                                <div style={{marginBottom:'50px'}}>
                                    {leaderboard.map((user, index) => (
                                        <div className={['task-list-item leaders',
                                            userIndex === index&&'golden'
                                        ].join(' ')} key={index}
                                        ref={userIndex === index ? myRef : null}

                                        >
                                            <div style={{flex:1,fontSize: index+1===1?'30px':index+1===2?'25px':index+1===3?'20px':'15px'}}>{index+1}.</div>
                                            <div style={{flex:1,fontWeight:'bold'}}>{LeaderBoardPretty(user.username)}</div>
                                            <div style={{flex:1}}>{numberPrettier(user.balance)} 🪙</div>
                                        </div>
                                    ))}
                                </div>
                            ):(
                                <h1>Be the first one!</h1>
                            )}
                        </div>

                    {(userIndex !== -1) && (
                        <div className={'task-list-item leaders floating golden'}
                        onClick={executeScroll}>
                            <div style={{flex:1,fontSize: userIndex+1===1?'30px':userIndex+1===2?'25px':userIndex+1===3?'20px':'15px'}}>{userIndex+1}.</div>
                            <div style={{flex:1,fontWeight:'bold'}}>{LeaderBoardPretty(userData.username)}</div>
                            <div style={{flex:1}}>{numberPrettier(userData.balance)} 🪙</div>
                        </div>
                    )}


                </div>


            </div>


        </div>
    )
}