
// add-ons that generate an hourly profit for the farm
export const miners = [
    {
        id:1,
        name: 'Care Taker',
        description: 'Let your farm thrive with the ultimate Care Taker. They’ll keep the animals happy and your profits rolling in. Because even farms need a little TLC!',
        cost: 1000,
        profit: 100,
        image: '/miner_CareTakers_.png'
    },
    {
        id:2,
        name: 'Farmer',
        description: 'This farmer’s got the green thumb and the golden touch. Watch your crops grow, your wallet swell, and your farm become the envy of the digital world.',
        cost: 2000,
        profit: 150,
        image: '/miner_Farmers_.png'
    },
    {
        id:3,
        name: 'Harvester',
        description: 'Moo-ve over! This cow’s behind the wheel, and it’s harvesting cash. Who knew udderly efficient farming could be so profitable?',
        cost: 50000,
        profit: 1000,
        image: '/miner_Harvester_.png'
    },
    {
        id:4,
        name: 'Combine',
        description: 'Get ready for a hay day with our cow-driven Combine! It’s more than just a tractor—it’s a money-making machine with horns.',
        cost: 7000,
        profit: 250,
        image: '/miner_Combine_.png'
    },
    {
        id:5,
        name: 'Worker',
        description: 'Your farm’s got hustle, thanks to this diligent Worker. They plant, they plow, and they turn your investment into sweet, sweet passive income.',
        cost: 5000,
        profit: 200,
        image: '/miner_Worker_.png'
    },
    {
        id:6,
        name: 'Marketing',
        description: 'Suit up! Our marketing cows are here to milk every opportunity. When these bovine execs talk, your profits listen.',
        cost: 6000,
        profit: 500,
        image: '/miner_Marketing_.png'
    },
    {
        id:7,
        name:'Store',
        description:'Open your farm’s doors to endless income. This Store’s stocked with everything you need to sell, grow, and rake in the digital dough.',
        cost: 10000,
        profit: 550,
        image: '/miner_Market_.png'
    },
    {
        id:8,
        name:'Smoothies',
        description:'Sip back and relax! Your farm’s Smoothie Bar is blending up juicy profits. Fresh, fruity, and financially rewarding!',
        cost: 2000,
        profit: 75,
        image: '/miner_Smoothie_.png'
    },
    {
        id:9,
        name: 'Barn',
        description: "Build a Barn, and watch your wealth take root. It’s where your animals live and your earnings grow, all under one sturdy roof.",
        cost: 5000,
        profit: 175,
        image: '/miner_Barn_.png'
    },
    {
        id:10,
        name: 'Tractor',
        description: 'Rev up your revenue with a powerhouse Tractor. It’s the engine of your farm’s success—because nothing says profit like horsepower.',
        cost: 60000,
        profit: 1100,
        image: '/miner_Tractor_.png'
    },
    {
        id:11,
        name: 'Instagram',
        description: 'Snap, post, profit! Your farm’s Instagram is buzzing with likes, follows, and a steady stream of passive income. Social media’s never been so lucrative!',
        cost: 3000,
        profit: 80,
        image: '/miner_Instagram_.png'
    },
    {
        id: 12,
        name: 'Pinterest',
        description: 'Pin your way to prosperity! Your farm’s Pinterest board is a visual feast of ideas—and every click is a step toward financial success.',
        cost: 3000,
        profit: 80,
        image: '/miner_Pinterest_.png'
    },
    {
        id: 13,
        name: 'Greenhouse',
        description: 'Grow profits year-round in your state-of-the-art Greenhouse. It’s the warm, green heart of your farm’s income, no matter the season.',
        cost: 40000,
        profit: 1050,
        image: '/miner_Greenhouse_.png'
    }
]
const priceMultiplier = {
    1: 1.0,
    2: 1.2,
    3: 1.4,
    4: 1.6,
    5: 1.8,
    6: 2,
    7: 2.2,
}

export const GetMinerPrice = (miner) => {
    return miner.cost * priceMultiplier[miner.level]
}
export const GetMinerProfit = (miner) => {

    return   miner.profit * priceMultiplier[miner.level]


}