
import {joinRoom} from "trystero/torrent";


const idsToUserData = {}
let retryCount = 0
export const SetUpWebRTC=(userData)=> {
    try {
        console.log('Connecting WebRTC')
        const ActiveSince = new Date()
        const config = {
            appId: 'TapFarmBNF3Wallet',
            relayRedundancy: 30,
            iceServers: [
                { urls: "stun:stun.l.google.com:19302" },
                {
                    urls: [
                        "turn:eu-0.turn.peerjs.com:3478",
                        "turn:us-0.turn.peerjs.com:3478",
                    ],
                    username: "peerjs",
                    credential: "peerjsp",
                },
            ],
            sdpSemantics: "unified-plan",
        }

        const room = joinRoom(config, 'general')
        const [sendUserData, getUserData] = room.makeAction('announceUser')

        const distributedDate = {
            username: userData.username,
            userId: userData.userId,
        }

// tell other peers currently in the room our name
        sendUserData(distributedDate)

// tell newcomers
        room.onPeerJoin(peerId => sendUserData(distributedDate, peerId))

// listen for peers naming themselves
        getUserData((name, peerId) => {
                idsToUserData[peerId] = name;
                console.log('silent join: ', name.username)
                // if 10 seconds passed since ActiveSince, then console.log joined
                if (new Date() - ActiveSince > 10000) {
                    console.log(`${name.username} joined`)
                }
            }
        )

        room.onPeerLeave(peerId =>
            console.log(`${idsToUserData[peerId]?.username || 'a weird stranger'} left`)
        )
    }catch(e){
        console.error('error SetUpWebRTC', e)
        console.log(' sleeping 5 seconds')
        if(retryCount>5){
            console.log('retryCount > 5')
            return
        }
        setTimeout(() => {
            console.log('retrying SetUpWebRTC')
            retryCount++
            SetUpWebRTC(userData)
        }, 5000)
    }
}