import React from "react";
import {T} from "../../utils/translations";
export const Footer = ({setActivePage}) => {

    return (
        <div className={"footer"}>
            <button onClick={() => setActivePage('home')}>

                <img src={"/farm_home.png"} alt={"home"}/>
                <br/>
                {T('Farm')}
            </button>
            <button onClick={() => setActivePage('Miner')}>
                <img src={"/pickaxe.png"}
                     alt={"miner"}
                     className={"dark"}/>
                <br/>
                {T('Miner')}

                <div className={"flash"}></div>
            </button>
            <button onClick={() => setActivePage('Tasks')}>

                <img src={"/calendar.png"}
                     alt={"tasks"}
                className={"dark"}
                />
                <br/>
                {T('Tasks')}
                <div className={"flash n"}> </div>
            </button>
            <button onClick={() => setActivePage('AirDrop')}>
                <img src={"/airdrop.png"}
                     alt={"airdrop"}/>
                <br/>
                {T('AirDrop')}
            </button>

        </div>
    )
}